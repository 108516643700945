<template>
    <Dialog header="Add account" v-model:visible="display" :modal="true" :closable="true"
        class="add-portfolio-account-modal">
        <Dropdown v-if="qualifiedAccountsList.length > 0" class="account-selector" v-model="selectedAccount"
            :options="qualifiedAccountsList" optionLabel="accountName">
        </Dropdown>
        <p style="text-align: center;" v-else>No Accounts Yet</p>

        <h6 style="text-align: center;" v-if="portfolio.showDollarAmounts">Note that this group portfolio is configured to show dollar amounts. Only add your account if you are comfortable with sharing this information.</h6>
        <template #footer>
            <Button class='p-button-raised p-button-rounded green-button__primary' label='Add' @click='addAccount' />
            <Button class='p-button-raised p-button-rounded red-button__primary' label='Cancel' @click='close' />
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';

import GroupService from '../../../service/GroupService';
import PortfolioService from '../../../service/PortfolioService';

import EventBus from '../../../event-bus';
import { mapState } from 'vuex';

export default {
    name: 'AddGroupPortfolioAccountModal',
    components: {
        Dialog, Dropdown
    },
    data() {
        return {
            display: false,
            loaded: false,
            callback: null,
            portfolio: null,
            noLinkedAccount: false,
            selectedAccount: null,
            qualifiedAccountsList: []
        };
    },

    computed: {
        ...mapState(['selectedGroup']),
    },

    methods: {
        open(data) {
            this.callback = data.callback;
            this.portfolio = data.portfolio;
            this.display = true;
            this.getListOfAccounts();
        },

        close() {
            this.display = false;
        },

        addAccount() {
            // console.log("selectedAccount", this.selectedAccount, this.portfolio);
            GroupService.addGroupPortfolioAccount(this.selectedGroup.groupId, this.portfolio.groupPortfolioId, this.selectedAccount.investmentAccountId).then(resp => {
                this.display = false;
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'info', summary: "Account Added.", detail: '', life: 4000, group: 'center' });
                    EventBus.emit('refresh-portfolio-overview');
                    EventBus.emit('track-adding-account-to-group-portfolio');
                    this.callback();
                } else {
                    this.$toast.add({ severity: 'error', summary: "Oops! Account not added.  If the problem persists contact support@invrs.com.", detail: '', life: 4000, group: 'center' });
                }
            });
        },

        getListOfAccounts() {
            let qualifiedAccountTypes = [];
            if(this.portfolio.allowPaperAnalysisAccounts){
                qualifiedAccountTypes.push("paperAnalysis");
            }
            if(this.portfolio.allowPaperTradingAccounts){
                qualifiedAccountTypes.push("paperTrading");
            }
            console.log("getListOfAccounts", this.portfolio, qualifiedAccountTypes.toString());
            PortfolioService.getListOfAccounts(qualifiedAccountTypes.toString()).then(accountsList => {
                if (accountsList.status === 200) {
                    this.loaded = true;
                    this.getDataFromListOfAccounts(accountsList.data);
                }
            });
        },

        getDataFromListOfAccounts(accountsList) {
            // console.log("getDataFromListOfAccounts ", accountsList);
            this.qualifiedAccountsList = [];

            if (accountsList.total === 0) {
                this.noLinkedAccount = true;
                return;
            }

            accountsList.accounts.forEach((acct) => {
                if (acct.hidden != true && acct.accountTypeId != 53 && !this.isAccountStillInImport(acct)) {
                    const accountName = this.buildAcctName(acct);

                    this.qualifiedAccountsList.push({ accountName: accountName, investmentAccountId: acct.investmentAccountId });
                }
            });

            this.selectedAccount = this.qualifiedAccountsList[0];
        },

        isAccountStillInImport(account) {
            if (account) {
                const status = account.lastSyncStatus;

                return status.indexOf('waiting') >= 0 || status.indexOf('Importing') >= 0;
            }

            return false;
        },

        buildAcctName(acct) {
            let suffix = ""

            if (acct.lastSyncStatus.indexOf("failed") > 0 || this.isAccountStillInImport(acct)) {
                suffix = `[${acct.lastSyncStatus}]`;
            }
            if (!acct.partialAccountNum || acct.partialAccountNum == '') {
                return acct.name + " (" + acct.extAccountId.substring(0, 8) + ")" + suffix;
            }
            else {
                try {
                    if (acct.partialAccountNum.length > 9) {
                        let lastDash = acct.partialAccountNum.lastIndexOf("-");
                        if (lastDash >= 0) {
                            return acct.name + " (" + acct.partialAccountNum.substring(lastDash + 1) + ")" + suffix;
                        }
                        else {
                            return acct.name + " (" + acct.partialAccountNum.substring(acct.partialAccountNum.length - 10) + ")" + suffix;
                        }
                    }
                    else {
                        return acct.name + " (" + acct.partialAccountNum + ")" + suffix;
                    }
                } catch (error) {
                    console.error("error building name returning default:" + error);
                    return acct.name + " (" + acct.partialAccountNum + ")" + suffix;
                }
            }
        },

    },
};
</script>

<style>
.add-portfolio-account-modal {
    font-family: 'Trebuchet MS', 'Verdana';
    width: 400px;
    border-radius: 16px;
}
</style>
<style scoped>
.account-selector {
    width: 100% !important;
}
</style>